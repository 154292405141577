var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showinputs)?_c('div',{staticClass:"row member-search",class:_vm.uniqueSearchItem ? 'justify-content-center' : ''},[(_vm.visibleSearchFields.includes('gender'))?_c('div',{class:_vm.searchColClass('gender')},[_c('b-form-group',{attrs:{"id":"input-group-1","label":_vm.$t('member_search.looking_for_gender'),"label-for":"input-1"}},[_c('b-form-select',{staticClass:"inputnewdesignselect",attrs:{"id":"input-1","options":_vm.genderOptions},on:{"change":() => {
              _vm.reload();
              _vm.clearSimilar();
            }},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.visibleSearchFields.includes('age'))?_c('div',{class:_vm.searchColClass('age')},[_c('b-form-group',{attrs:{"id":"input-group-4","label":_vm.$t('member_search.looking_for_age'),"label-for":"input-4"}},[_c('b-form-select',{staticClass:"inputnewdesignselect",attrs:{"id":"input-4","options":_vm.ageOptions},on:{"change":() => {
              _vm.reload();
              _vm.clearSimilar();
            }},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.visibleSearchFields.includes('country'))?_c('div',{class:_vm.searchColClass('country')},[_c('b-form-group',{attrs:{"id":"input-group-2","label":_vm.$t('member_search.looking_for_country'),"label-for":"input-2"}},[_c('b-form-select',{staticClass:"inputnewdesignselect",attrs:{"id":"input-2","options":_vm.countryOptions},on:{"change":() => {
              _vm.countryregion = 0;
              _vm.reload();
              _vm.clearSimilar();
            }},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.visibleSearchFields.includes('region'))?_c('div',{class:_vm.searchColClass('region')},[_c('b-form-group',{attrs:{"id":"input-group-3","label":_vm.$t('member_search.looking_for_region'),"label-for":"input-3"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(
                _vm.$store.state.geolocation_permission !== 'denied' &&
                _vm.$store.state.geolocation_permission !== 'unsupported' &&
                _vm.$auth.loggedIn
              )?_c('b-input-group-text',{staticClass:"geolocation-btn",class:{
                active:
                  _vm.reverseGeocode &&
                  _vm.reverseGeocode.countryregion &&
                  _vm.reverseGeocode.countryregion === _vm.countryregion,
                error: _vm.locationError,
              },on:{"click":_vm.getLocation}},[(
                  _vm.isLoadingGeolocation &&
                  _vm.$store.state.geolocation_permission === 'granted'
                )?_c('font-awesome-icon',{attrs:{"icon":['far', 'spinner'],"spin":""}}):_c('font-awesome-icon',{attrs:{"size":"lg","icon":['far', 'location-arrow']}})],1):_vm._e()]},proxy:true}],null,false,1131072799)},[_vm._v(" "),_c('b-form-select',{staticClass:"inputnewdesignselect",attrs:{"id":"input-3","options":_vm.countryRegionOptions},on:{"change":() => {
                _vm.reload();
              }},model:{value:(_vm.countryregion),callback:function ($$v) {_vm.countryregion=$$v},expression:"countryregion"}})],1)],1)],1):_vm._e()]):_vm._e(),_vm._v(" "),(
      !_vm.loading &&
      _vm.profiles !== null &&
      _vm.profiles !== undefined &&
      _vm.profiles.length == 0
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',[_vm._v(_vm._s(_vm.$t('member_search.none_found')))])])]):_vm._e(),_vm._v(" "),_c('profile-overview',{attrs:{"profiles":_vm.profiles,"xcams-profiles":_vm.webcamResults,"enable-resell-cam-profiles":_vm.$store.state.settings.member_resell.includes('camads'),"enable-cam-profiles":_vm.$store.state.settings.member_resell.includes('cams')}}),_vm._v(" "),(_vm.showPaging && _vm.showpages)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!_vm.loading || _vm.numResults > 0)?_c('b-pagination',{staticClass:"pt-3 flex-wrap",attrs:{"total-rows":_vm.numResults,"per-page":_vm.perPage,"size":_vm.$ua.isFromPc() ? 'md' : 'sm'},model:{value:(_vm.displayPage),callback:function ($$v) {_vm.displayPage=$$v},expression:"displayPage"}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(
      _vm.similarNumResults > 0 && _vm.similarProfiles.length && _vm.allProfilesLoaded
    )?_c('div',[_c('div',{staticClass:"row display-all-similar-container flex mb-4 mb-lg-3"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-6 col-xl-4 align-self-center text-center"},[_c('h2',{staticClass:"h2_similar_title font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.$t('other_members_near_you'))+"\n        ")])]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('profile-overview',{attrs:{"profiles":_vm.similarProfiles}}),_vm._v(" "),(_vm.similarShowPaging && _vm.similarShowpages)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(!_vm.loading || _vm.similarNumResults > 0)?_c('b-pagination',{staticClass:"pt-3 flex-wrap",attrs:{"total-rows":_vm.similarNumResults,"per-page":_vm.perPage,"size":_vm.$ua.isFromPc() ? 'md' : 'sm'},model:{value:(_vm.similarDisplayPage),callback:function ($$v) {_vm.similarDisplayPage=$$v},expression:"similarDisplayPage"}}):_vm._e()],1)]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container",attrs:{"id":"loadMore"}},[(_vm.loading)?_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['far', 'spinner'],"spin":""}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3 col-xl-4 align-self-center d-none d-lg-inline-block"},[_c('div',{staticClass:"divider"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-3 col-xl-4 align-self-center"},[_c('div',{staticClass:"divider"})])
}]

export { render, staticRenderFns }